import TTLCache from '@isaacs/ttlcache';
import { revalidate as solidRevalidate } from '@solidjs/router';
import { isServer } from 'solid-js/web';
import type { OperationNames } from './__generated__/operations';

export const ttlCache = isServer ? null : new TTLCache({ max: 50, ttl: 60_000 * 4 });

export type CacheKey = OperationNames;

/**
 * Revalidate cached data.
 * This is a wrapper around Solid Router’s `revalidate()` method that also clears the client’s local
 * in-memory TTL cache.
 */
export async function revalidate(
	/**
	 * The cache key or keys to revalidat.
	 * Sending `undefined` for this value is equivalent to revalidating _all_ cached data.
	 * An empty array will revalidate nothing.
	 */
	key?: CacheKey | Array<CacheKey> | void,
	/**
	 * Forces Solid Router to trigger a cache miss on the key
	 */
	force?: boolean,
) {
	if (Array.isArray(key) && key.length === 0) {
		return;
	}
	if (ttlCache) {
		const cacheKeys = Array.from(ttlCache.keys()) as Array<CacheKey>;
		if (key) {
			const keys = Array.isArray(key) ? key : [key];
			for (const key of keys) {
				if (key.includes('[')) {
					ttlCache.delete(key);
				} else {
					cacheKeys.filter((k) => k.startsWith(`${key}[`) || k === key).forEach((key) => ttlCache.delete(key));
				}
			}
		} else {
			ttlCache.clear();
		}
	}

	await solidRevalidate(key, force);
}
